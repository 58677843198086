import React from 'react';
import NavBar2 from './NavBar2'; // import your Navbar component
import Footer from './Footer'; // import your Footer component

const PageWrapper = ({ children }) => {
  return (
    <div>
      <NavBar2 />
      <main>
        {children} {/* This is where the content of the child components will be rendered */}
      </main>
      <Footer />
    </div>
  );
};

export default PageWrapper;