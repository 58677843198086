import React from "react";

import { clarity } from "react-microsoft-clarity";

import "./App.css";
import Home from "./Pages/Home";
import Refer from "./Pages/Refer";
import PageWrapper from "./Structure/PageWrapper";
import {Helmet} from "react-helmet";

import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "refer/",
    element: <Refer />,
  },
]);

function App() {
  //add useEffect for clarity setup
  React.useEffect(() => {
    clarity.init("ljwp3ul0sf");
    clarity.consent();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Apex Cloud Partners</title>
        <link rel="canonical" href="https://www.apexcloudpartners.com" />
        <meta name="description" content="Navigating cloud costs can be complex, but with our strategic cost optimization and negotiation services, you're set for success. Leverage our expertise to negotiate favorable terms, identify cost-saving opportunities, and implement effective cloud management strategies." />
        <meta name="keywords" content="cloud cost optimization, negotiation services, competitive advantage, cloud vendors, performance, cost-saving opportunities, cloud management strategies" />
      </Helmet>

      <PageWrapper>
        <RouterProvider router={router} />
      </PageWrapper>
    </div>
  );
}

export default App;
