// create a react functional component called Refer

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Toast from "react-bootstrap/Toast";
import PageHeading from "../Components/PageHeading";

const Refer = () => {
  const handleSayHi = (event) => {
    const host = "/api/AddReferForm";

    //post to the api using axios
    axios
      .post(host, {
        name: name,
        contactEmail: contactEmail,
        phone: phone,
      })
      .then(
        (response) => {
          console.log(response);

          setContactEmail("");
          setName("");
          setPhone("");

          setShowToast(true);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const [name, setName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showToast, setShowToast] = useState(false);

  return (
    <div>
      <PageHeading
        heading_text={"Refer Us!"}
        subheading_text={"Your connections are valuable. Refer your friends and earn a percentage back — the more they save, the more you earn!"}
      />

      <section className="" id="section_intro">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mx-auto">



              <p>
              Unlock the potential of your social network by introducing your friends to our services. Every referral not only brings you a percentage of their savings but also opens the door to a rewarding journey where connections turn into cash. The more your friends save, the more you earn, turning each introduction into an opportunity for mutual benefit. It’s simple: your referrals save money, and you earn a reward for making it happen. So, why wait? Start referring today and watch your earnings grow as your friends discover the value of saving
              </p>

            </div>
          </div>
        </div>
      </section>

      <section className="" id="section_6">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mx-auto">
              <Toast show={showToast}>
                <Toast.Header>
                  <strong className="me-auto">Thank You {name}</strong>
                  <small>Success!</small>
                </Toast.Header>
                <Toast.Body>Woohoo, we will contact you soon!</Toast.Body>
              </Toast>

              <Form>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-12 my-2">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="name"
                        placeholder="Enter Name"
                        required
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-md-4 col-12 my-2">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        value={contactEmail}
                        placeholder="Enter email"
                        required
                        onChange={(e) => {
                          setContactEmail(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-md-4 col-12 my-2">
                    <Form.Group className="mb-3" controlId="formBasicPhone">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter phone"
                        required
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Form>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 my-2">
                  <Button
                    variant="primary"
                    type="submit"
                    className="custom-btn btn btn-lg"
                    onClick={handleSayHi}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Refer;
