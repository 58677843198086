//create a react functional component called NavBar

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { HashLink, NavHashLink } from "react-router-hash-link";

const NavBar2 = () => {
  const [showMenuShade, setShowMenuShade] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  const toggleBodyClass = () => {

    if (window.scrollY > 200) {
      setShowMenuShade(true);
    } else {
      setShowMenuShade(false);
    }
  };





  useEffect(() => {

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener("scroll", toggleBodyClass);
    toggleBodyClass(); // Initial check

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener("scroll", toggleBodyClass);
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const navBarClass = isMobile || showMenuShade ? "apex-navbar-white" : "apex-navbar-transparent";

  return (
    <>
      <Navbar
        className={
          navBarClass
        }
        fixed="top"
        expand="lg"

       
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              src="images/ApexLogo.png"
              alt="Apex Cloud Partners"
              width="40"
              height="40"
              className="d-inline-block align-text-top"
            />
            {" "} Apex Cloud Partners
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/#section_2">About</Nav.Link>
              <Nav.Link href="/#section_3">Services</Nav.Link>
              <Nav.Link href="/#section_4">Results</Nav.Link>
              <Nav.Link href="/#section_6">Contact</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link href="/refer">
                <Button variant="primary" className="custom-btn btn btn-lg">
                  Refer Us<i className="bi-chat ms-2"></i>
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar2;
