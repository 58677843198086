import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Toast from "react-bootstrap/Toast";
import Hero from "../Components/Hero";

import Accordion from "react-bootstrap/Accordion";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function Home() {
  const [name, setName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [showToast, setShowToast] = useState(false);

  const handleSayHi = (event) => {
    const host = "/api/AddContactForm";
    //post to the api using axios
    axios
      .post(host, {
        name: name,
        contactEmail: contactEmail,
        phone: phone,
        description: description,
      })
      .then(
        (response) => {
          console.log(response);

          setContactEmail("");
          setName("");
          setPhone("");
          setDescription("");

          setShowToast(true);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(user, email);
  };

  return (
    <div>
      {" "}
      <Hero hero_text={"Tame Your Cloud Expenses, Unleash Your Savings."} />
      <section className="about section-padding" id="section_2">
        <div className="container">
          <div className="row">


            <Tabs id="uncontrolled-tab-example" className="mb-3" >
              <Tab eventKey="Introduction" title="Introduction">
                <div className="row">
                  <div className="col-lg-6 col-12 mb-lg-0 mb-4">
                    <img
                      src="images/intro/pexels-jopwell-2422293.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>

                  <div className="col-lg-5 col-12 m-auto">
                    <h3 className="mb-3">Cost Mastery</h3>

                    <p>
                      Navigating cloud costs can be complex, but with our
                      strategic cost optimization and negotiation services,
                      you're set for success. We specialize in turning cloud
                      cost management into a competitive advantage for your
                      business, ensuring you get the best value from your cloud
                      vendors without compromising on performance.
                    </p>

                    <p>
                      Leverage our expertise to negotiate favorable terms,
                      identify cost-saving opportunities, and implement
                      effective cloud management strategies. Our approach is
                      tailored to your unique needs, ensuring you can focus on
                      what you do best—growing your business with the right
                      cloud solutions at your side.
                    </p>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Profile">
                <div className="row">
                  <div className="col-lg-5 col-12 m-auto">
                    <h3 className="mb-3">
                      Michael Richardson, CEO & Co-Founder
                    </h3>

                    <p>
                      Michael Richardson brings extensive commercial expertise
                      to the table, with a proven track record in procurement
                      and sales that encompasses negotiating over $5 billion in
                      contracts. His strategic vision and depth of experience
                      drive the company's success in delivering exceptional
                      value to our clients.
                    </p>

                    <h3 className="mb-3">Thomas Clarke, CTO & Co-Founder</h3>

                    <p>
                      Thomas Clarke is a seasoned expert in the cloud space,
                      specializing in optimizing large estates with over 20
                      years of experience. His comprehensive knowledge and
                      innovative approaches are pivotal in helping clients
                      maximize their cloud investments and operational
                      efficiencies.
                    </p>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="faq" title="FAQ">
                <div className="row">
                  <div className="col-lg-6 col-12 mb-lg-0 mb-4">
                    <img
                      src="images/intro/pexels-fauxels-3183197.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>

                  <div className="col-lg-5 col-12 m-auto">
                    <h3 className="mb-3">Frequenlty Asked Questions</h3>

                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          {" "}
                          How can CFOs protect their organizations from
                          aggressive sales tactics and ensure fair cloud
                          contracts?
                        </Accordion.Header>
                        <Accordion.Body>
                          In navigating the complexities of cloud contracts,
                          CFOs play a crucial role in safeguarding their
                          organizations against aggressive sales tactics. It's
                          essential to approach negotiations with a clear
                          understanding of your company's cloud strategy and
                          long-term objectives. To ensure fair contracts, we
                          recommend conducting thorough market research,
                          leveraging competitive bids, and seeking expert
                          negotiation support. By doing so, CFOs can secure
                          favorable terms that align with their financial goals
                          and cloud needs, avoiding unnecessary costs and
                          restrictive long-term commitments.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          What strategies can CIOs employ to align cloud costs
                          with their company's strategic goals?
                        </Accordion.Header>
                        <Accordion.Body>
                          CIOs are instrumental in aligning cloud costs with the
                          strategic goals of their organizations. This involves
                          a meticulous evaluation of current and future cloud
                          needs in relation to the company's overall strategy.
                          To achieve this alignment, it's crucial to implement a
                          robust cloud cost management framework that includes
                          regular reviews of cloud usage, cost optimization
                          practices, and renegotiation of contracts based on
                          performance and changing needs. Additionally,
                          fostering a strong partnership with CFOs ensures that
                          cloud investments are continuously evaluated for their
                          ROI and contribution to strategic objectives.
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          How can companies effectively manage long-term cloud
                          contracts to avoid overspending while still supporting
                          their cloud strategy?
                        </Accordion.Header>
                        <Accordion.Body>
                          Managing long-term cloud contracts effectively
                          requires a balance between securing competitive
                          pricing and maintaining flexibility to adapt to
                          changing business needs. Companies can achieve this by
                          negotiating contracts with scalability in mind,
                          allowing for adjustments in services and costs as
                          demands evolve. Regularly reviewing contracts in the
                          context of your cloud strategy and current market
                          offerings is vital. This proactive approach, combined
                          with clear exit strategies and periodic renegotiation
                          opportunities, empowers companies to stay aligned with
                          their strategic goals without being locked into
                          disadvantageous terms or overspending.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
      <section className="services section-padding" id="section_3">
        <div className="container">
          <div className="row">
            <h2 className="mb-5 text-center">Services</h2>

            <div className="col-lg-4 col-12 d-flex bg-primary p-0">
              <img
                src="images/services/undraw_Online_page_re_lhgx.svg"
                className="img-fluid services-image"
                alt=""
              />
            </div>

            <div className="col-lg-4 col-12 p-0">
              <div className="services-info custom-icon-left paddingText">
                <h4 className="">Cloud Cost Analysis and Optimization</h4>

                <p className="mb-4">
                  We meticulously analyze your current cloud infrastructure to
                  identify inefficiencies and recommend optimizations,
                  potentially saving <strong>up to 30%</strong> on your cloud
                  expenses.
                </p>

                <a className="custom-btn custom-bg-dark btn">Learn More</a>
              </div>
            </div>

            <div className="col-lg-4 col-12 d-flex bg-warning p-0">
              <img
                src="images/services/undraw_online_transactions_02ka.svg"
                className="img-fluid services-image"
                alt=""
              />
            </div>

            <div className="col-lg-4 col-12 p-0 order-lg-0 order-sm-5">
              <div className="services-info custom-icon-right paddingText order-lg-0 order-sm-2">
                <h4 className="">Contract Negotiation and Management</h4>

                <p className="mb-4">
                  By leveraging our extensive experience and market knowledge,
                  we negotiate and manage your cloud contracts to secure terms
                  that can reduce costs <strong>by as much as 25%</strong>,
                  ensuring you only pay for what you truly need.
                </p>

                <a className="custom-btn custom-bg-dark btn">Learn More</a>
              </div>
            </div>

            <div className="col-lg-4 col-12 d-flex bg-success p-0 order-lg-0 order-sm-4">
              <img
                src="images/services/undraw_content_team_3epn.svg"
                className="img-fluid services-image"
                alt=""
              />
            </div>

            <div className="col-lg-4 col-12 p-0">
              <div className="services-info custom-icon-top paddingText">
                <h4 className="">Cloud Usage Forecasting and Budgeting</h4>

                <p className="mb-4">
                  Our strategic forecasting and budgeting services empower your
                  business to plan more effectively for future cloud needs,
                  helping to prevent overspending and achieve savings of
                  <strong>up to 20%</strong> on future cloud expenditures.
                </p>

                <a className="custom-btn custom-bg-dark btn">Learn More</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="work section-padding" id="section_4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-5 text-center">Results</h2>
            </div>
          </div>
          <div className="row">

            <div className="col-lg-6 col-12 mb-lg-0 mb-4">

             
                    <img
                      src="images/work/business-chart-visual-graphics-report-concept.jpg"
                      className="img-fluid work-image"
                      alt=""
                    />
            
                </div>

                <div className="col-lg-5 col-12 m-auto">
          
                  <p>
                    Apex Cloud Partners recently partnered with a multinational
                    corporation grappling with a steep increase in cloud costs,
                    with annual expenditures exceeding{" "}
                    <strong>$30 million</strong> and a growth rate of 12% per
                    year. By implementing our Cloud Cost Analysis and
                    Optimization service, we identified inefficiencies and
                    optimized the client's cloud utilization, leading to a
                    significant <strong>30% cost reduction</strong>, translating
                    into <strong>$9 million</strong> in total savings.
                    Furthermore, our Contract Negotiation and Management service
                    enabled us to renegotiate the client’s cloud contracts,
                    achieving an additional <strong>25% reduction</strong> in
                    costs and yielding further total savings of{" "}
                    <strong>$7.5 million</strong>.
                  </p>
                  <p>
                    Continuing our efforts, the Cloud Usage Forecasting and
                    Budgeting service provided a strategic roadmap for managing
                    future cloud expenses, effectively preventing the
                    anticipated annual spending increase. This proactive
                    approach resulted in a <strong>20% savings</strong> on
                    forecasted future expenditures, equivalent to{" "}
                    <strong>$6 million</strong> saved against the expected
                    growth trend. This comprehensive strategy not only curbed
                    the escalating cloud costs but also positioned the company
                    to leverage their cloud infrastructure as a competitive
                    advantage, ensuring financial efficiency and operational
                    scalability.
                  </p>
                </div>
        
   
          </div>
        </div>
      </section>
      {/* 
        <section className="pricing section-padding" id="section_5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="text-white mb-5 text-center">Tween Marketing Plans</h2>
              </div>
  
              <div className="col-lg-6 col-12">
                <div className="pricing-plan bg-white">
                  <div className="d-flex flex-wrap align-items-center pricing-header">
                    <i className="pricing-icon bi-person"></i>
  
                    <div className="pricing-name">
                      <h4>Sufficient</h4>
                    </div>
  
                    <div className="pricing-price">
                      <h4>$4,600</h4>
                    </div>
  
                    <small className="pricing-recommend">Recommended Plan</small>
                  </div>
  
                  <div className="pricing-body">
                    <h5 className="mb-3">Managed Features</h5>
  
                    <ul className="pricing-list">
                      <li className="pricing-list-item">Fully Digital</li>
                      <li className="pricing-list-item">Social Media Managements</li>
                      <li className="pricing-list-item">Website Optimizations</li>
                      <li className="pricing-list-item">
                        24/7 Phone Calls and Emails
                      </li>
                    </ul>
  
                    <h5 className="mt-lg-5 mt-4 mb-3">
                      Small to Mid-size Businesses
                    </h5>
  
                    <p>Everything you need for your digital marketing stuffs</p>
                  </div>
                </div>
              </div>
  
              <div className="col-lg-6 col-12 mt-lg-0 mt-5">
                <div className="pricing-plan border-0 bg-white shadow-lg">
                  <div className="d-flex flex-wrap align-items-center pricing-header">
                    <i className="pricing-icon bi-briefcase"></i>
  
                    <div className="pricing-name">
                      <h4>Complete</h4>
                    </div>
  
                    <div className="pricing-price">
                      <h4>$8,200</h4>
                    </div>
  
                    <small className="pricing-recommend">Ecommerce Plan</small>
                  </div>
  
                  <div className="pricing-body">
                    <h5 className="mb-3">Fully Featured Work</h5>
  
                    <ul className="pricing-list">
                      <li className="pricing-list-item">Completely Digital</li>
                      <li className="pricing-list-item">Everything managed online</li>
                      <li className="pricing-list-item">Full Ecommerce Features</li>
                      <li className="pricing-list-item">24/7 Instant Video Chats</li>
                    </ul>
  
                    <h5 className="mt-lg-5 mt-4 mb-3">High-end Ecommerce Websites</h5>
  
                    <p>Fit for your company and ecommerce related stuffs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overlay dark-overlay"></div>
        </section>
  */}
      <section className="section-padding" id="section_6">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mx-auto">
              <h2 className="mb-4 text-center">
                Say Hi
                <i className="bi-chat ms-2"></i>
              </h2>

              <Toast show={showToast}>
                <Toast.Header>
                  <strong className="me-auto">Thank You {name}</strong>
                  <small>Success!</small>
                </Toast.Header>
                <Toast.Body>Woohoo, we will contact you soon!</Toast.Body>
              </Toast>

              <Form>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-12 my-2">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="name"
                        placeholder="Enter Name"
                        required
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-md-4 col-12 my-2">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        value={contactEmail}
                        placeholder="Enter email"
                        required
                        onChange={(e) => {
                          setContactEmail(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-md-4 col-12 my-2">
                    <Form.Group className="mb-3" controlId="formBasicPhone">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter phone"
                        required
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12 my-2">
                    <Form.Group className="mb-3" controlId="formBasicProject">
                      <Form.Label>Describe Your Project</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Project Description"
                        rows="3"
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        value={description}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Form>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 my-2">
                  <Button
                    variant="primary"
                    type="submit"
                    className="custom-btn btn btn-lg"
                    onClick={handleSayHi}
                  >
                    Submit
                  </Button>
                </div>
              </div>

              {/* 
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-12 my-2">
                      <label className="mb-2" htmlFor="name">
                        Full Name
                      </label>

                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        required=""
                      />
                    </div>

                    <div className="col-lg-4 col-md-6 col-12 my-2">
                      <label className="mb-2" htmlFor="email">
                        Email Address
                      </label>

                      <input
                        type="email"
                        name="email"
                        id="email"
                        pattern="[^ @]*@[^ @]*"
                        className="form-control"
                        required=""
                      />
                    </div>

                    <div className="col-lg-4 col-md-6 col-12 my-2">
                      <label className="mb-2" htmlFor="subject">
                        How did you know about us?
                      </label>

                      <select
                        className="form-select form-control"
                        name="subject"
                        id="subject"
                      >
                        <option selected="">Choose a subject</option>
                        <option value="web+search">Website Searches</option>
                        <option value="social+media">Social Media</option>
                        <option value="others">Others</option>
                      </select>
                    </div>

                    <div className="col-12 my-2">
                      <label className="mb-2" htmlFor="message">
                        Tell us about the project
                      </label>

                      <textarea
                        className="form-control"
                        rows="5"
                        id="message"
                        name="message"
                      ></textarea>

                      <button
                        type="submit"
                        className="form-control mt-4"
                        id="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
         **/}
            </div>
          </div>
        </div>
      </section>
      {/* JAVASCRIPT FILES */}
      <script src="js/jquery.min.js"></script>
      <script src="js/popper.js"></script>
      <script src="js/bootstrap.min.js"></script>
      <script src="js/jquery.backstretch.min.js"></script>
      <script src="js/jquery.magnific-popup.min.js"></script>
      <script src="js/magnific-popup-options.js"></script>
      <script src="js/click-scroll.js"></script>
      <script src="js/custom.js"></script>
    </div>
  );
}

export default Home;
