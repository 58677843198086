// create a functional react component called Hero

import React from "react";

const Hero = ({hero_text}) => {
  return (
    <section
    className="hero  d-flex justify-content-center align-items-center"
    id="section_1"
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-12">
          <div className="heroText">
            <h1 className="text-white mb-lg-5 mb-4">
              {hero_text ?? "Welcome to our website"}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div className=" overlay-img "></div>
  </section>
  );
};

export default Hero;
