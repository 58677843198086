

// create a functional component called Footer

import React from 'react';


const Footer = () => {
    return (
        <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-12 me-auto mb-4">
              {/** 
              <h5 className="text-white mb-3">Newsletter</h5>

              <div className="row">
                <div className="col-lg-8 col-md-8 col-7">
                  <input
                    type="email"
                    name="subscribe-email"
                    id="subscribe-email"
                    pattern="[^ @]*@[^ @]*"
                    className="form-control"
                    placeholder="Your email address"
                    required=""
                  />
                </div>

                <div className="col-lg-4 col-md-4 col-5">
                  <button type="submit" className="form-control" id="subscribe">
                    Subscribe
                  </button>
                </div>
              </div>
            */}
            </div>

            <div className="col-lg-2 col-12 mx-auto my-lg-0 my-4">
              <h5 className="text-white mb-3">Services</h5>

              <ul className="footer-menu">
                <li className="footer-menu-item">
                  <a href="#" className="footer-menu-link">
                    Cloud Cost Analysis and Optimization
                  </a>
                </li>

                <li className="footer-menu-item">
                  <a href="#" className="footer-menu-link">
                    Contract Negotiation and Management
                  </a>
                </li>

                <li className="footer-menu-item">
                  <a className="footer-menu-link">
                    Cloud Usage Forecasting and Budgeting
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-12">
              <h5 className="text-white mb-3">Say Hi</h5>

              <p className="text-white mb-1">1209 Mountain Road Pl NE STE R, Albuquerque, New Mexico, USA</p>

              <p>
                <a
                  href="mailto:sales@apexcloudpartners.com"
                  className="footer-link"
                >
                  sales@apexcloudpartners.com
                </a>
              </p>
              <p></p>
            </div>

            <div className="site-footer-bottom mt-5">
              <div className="row pt-4">
                <div className="col-lg-6 col-12">
                  <p className="copyright-text tooplate-link">
                    Copyright © 2024 Apex Cloud Partners LLC
                  </p>
                </div>

                <div className="col-lg-3 col-12 ms-auto">
                  <ul className="social-icon">
                    {/*
                    <li><a href="#" className="social-icon-link bi-facebook"></a></li>
  
                    <li>
                      <a
                        href="https://twitter.com/search?q=tooplate"
                        className="social-icon-link bi-twitter"
                      ></a>
                    </li>
  
                    <li>
                      <a href="#" className="social-icon-link bi-instagram"></a>
                    </li>
  
                    <li><a href="#" className="social-icon-link bi-linkedin"></a></li>
  
                    <li><a href="#" className="social-icon-link bi-youtube"></a></li>
                    */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
}

export default Footer;