// create a functional react component called Hero

import React from "react";

const PageHeading = ({ heading_text, subheading_text }) => {
  return (
    <section
      className="page-header d-flex justify-content-center align-items-center"
      id="section_page_heading"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-12">
            <div className="heroText">
              <h1 className="text-white mb-lg-5 mb-4">
                {heading_text ?? "Our Heading"}
              </h1>

              <h4 className="text-white mb-lg-5 mb-4">
                {subheading_text ?? ""}
              </h4>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageHeading;
